const EditDataFields: { [key: string]: string } = {
  status: "status",
  editDataMultiplierIdentifierNumber: "CNPJ Multiplicador",
  editDataNfe: "NF-e",
  editDataCfop: "CFOP",
  editDataBatch: "Lote",
  editDataIdentifierNumber: "CNPJ/CPF",
  editDataUf: "UF",
  editDataCity: "Município",
  editDataBillingDate: "Data do faturamento",
  editDataSeedUse: "Uso da Semente",
  editDataCultivate: "Cultivar",
  editDataQtyPackaging: "Qtd. Embalagem",
  editDataClientName: "Nome Cliente",
  editDataTechnology: "Tecnologia",
  editDataWeightPackaging: "Peso Embalagem",
  editDataWeightKq: "Qtd. Kg",
  revision: "Em revisão",
  editDataDevolutionNfe: "Nf-e Devolução",
  editDataCustomerName: "Nome Cliente",
};

export const DataFields: { [key: string]: string } = {
  status: "status",
  dataMultiplierIdentifierNumber: "CNPJ Multiplicador",
  dataNfe: "NF-e",
  dataBatch: "Lote",
  dataCfop: "CFOP",
  dataIdentifierNumber: "CNPJ/CPF",
  dataUf: "UF",
  dataCity: "Município",
  dataBillingDate: "Data do faturamento",
  dataSeedUse: "Uso da Semente",
  dataCultivate: "Cultivar",
  dataQtyPackaging: "Qtd. Embalagem",
  dataClientName: "Nome Cliente",
  dataTechnology: "Tecnologia",
  dataWeightPackaging: "Peso Embalagem",
  dataWeightKq: "Qtd. Kg",
  revision: "Em revisão",
};

export default EditDataFields;
