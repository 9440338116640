/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateCfopDto,
  PaginationResponseDto,
} from '../models/index';
import {
    CreateCfopDtoFromJSON,
    CreateCfopDtoToJSON,
    PaginationResponseDtoFromJSON,
    PaginationResponseDtoToJSON,
} from '../models/index';

export interface CfopControllerCreateRequest {
    createCfopDto: CreateCfopDto;
    xOwnerId?: string;
}

export interface CfopControllerFindAllRequest {
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    cfop?: string;
}

export interface CfopControllerRemoveRequest {
    id: number;
    xOwnerId?: string;
}

/**
 * 
 */
export class CfopApi extends runtime.BaseAPI {

    /**
     */
    async cfopControllerCreateRaw(requestParameters: CfopControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createCfopDto'] == null) {
            throw new runtime.RequiredError(
                'createCfopDto',
                'Required parameter "createCfopDto" was null or undefined when calling cfopControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/cfop`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCfopDtoToJSON(requestParameters['createCfopDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cfopControllerCreate(requestParameters: CfopControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cfopControllerCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async cfopControllerFindAllRaw(requestParameters: CfopControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['cfop'] != null) {
            queryParameters['cfop'] = requestParameters['cfop'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/cfop`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async cfopControllerFindAll(requestParameters: CfopControllerFindAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.cfopControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cfopControllerRemoveRaw(requestParameters: CfopControllerRemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling cfopControllerRemove().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/cfop/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cfopControllerRemove(requestParameters: CfopControllerRemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cfopControllerRemoveRaw(requestParameters, initOverrides);
    }

}
