// src/pages/HomePage.js
import React, { createRef, useState } from "react";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { useAPI } from "../../client-api/utils/api";
import { CfopApi, CfopControllerFindAllRequest } from "../../client-api";
import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popconfirm, Space } from "antd";

const CfopList: React.FC = () => {
  const api = useAPI(CfopApi);
  const actionRef: any = createRef<ActionType>();
  const [loading, setLoading] = useState(false);

  const cfopColumns: ProColumns[] = [
    {
      title: "id",
      dataIndex: "id",
    },
    {
      dataIndex: "cfop",
      title: "CFOP",
      hideInTable: false,
    },
    {
      title: <EllipsisOutlined />,
      key: "options",
      width: 64,
      render: (_, record) => (
        <Space.Compact block>
          <Popconfirm
            title="Remover registro"
            description="Deseja realmente excluir esse CFOP?"
            onConfirm={() => {
              api
                .cfopControllerRemove({ id: record.id })
                .then((e) => actionRef?.current?.reload());
            }}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              title="Excluir"
              danger
              icon={<DeleteOutlined />}
              size="small"
            />
          </Popconfirm>
        </Space.Compact>
      ),
    },
  ];

  return (
    <Space direction="vertical">
      <Form
        layout="inline"
        onFinish={(values) => {
          const cfop = { cfop: values.cfop };
          api.cfopControllerCreateRaw({ createCfopDto: cfop });
          console.log(values);
        }}
      >
        <Form.Item
          label="CFOP"
          name="cfop"
          rules={[
            {
              required: true,
              message: "Por favor, informe o código do CFOP.!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={null}>
          <Button type="primary" htmlType="submit">
            Adicionar
          </Button>
        </Form.Item>
      </Form>
      <ProTable
        loading={loading}
        columns={cfopColumns}
        actionRef={actionRef}
        options={false}
        request={(params, sorter, filter) => {
          setLoading(true);
          const query = {
            page: params?.current,
            pageSize: params?.pageSize,
            ...params,
          } as CfopControllerFindAllRequest;
          return api
            .cfopControllerFindAll(query)
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            })
            .finally(() => setLoading(false));
        }}
        rowKey="id"
        pagination={{ defaultPageSize: 10 }}
        search={false}
      />
    </Space>
  );
};

export default CfopList;
