import React from "react";
import { PageContainer } from "@ant-design/pro-components";
import { Collapse } from "antd";
import type { CollapseProps } from "antd";
import CfopList from "../../components/cfop/cfop-list";

const SettingsPage: React.FC = () => {
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Código Fiscal de Operações e de Prestações (CFOP)",
      children: <CfopList />,
    },
    {
      key: "2",
      label: "Safras",
      children: <p>---</p>,
    },
    {
      key: "3",
      label: "Opções",
      children: <p>---</p>,
    },
  ];

  return (
    <PageContainer title={false}>
      <Collapse items={items} accordion />
    </PageContainer>
  );
};

export default SettingsPage;
