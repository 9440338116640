import React, { useEffect, useRef } from "react";
import type { ProColumns } from "@ant-design/pro-components";
import { ActionType, ProTable } from "@ant-design/pro-components";
import { EllipsisOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import {
  DevolutionsInvoicesNotesDataApi,
  InvoicesNotesDataApi,
  InvoicesNotesDataControllerFindAllRequest,
  RoyaltiesNotesDataApi,
  RoyaltiesNotesDataControllerFindAllRequest,
} from "../../client-api";
import { useAPI } from "../../client-api/utils/api";
import { royaltiesNotesDataColumns } from "../../pages/royalties-notes";
import EditDataFields from "../../pages/client-billing/data-fields";
import TagInvoice from "../invoices-status/tag-invoice";
import ClientBillingEdit from "../client-billing-edit/indes";

type InvoicesRecordsProps = {
  keyIntegration: string;
};

const InvoicesRecords: React.FC<InvoicesRecordsProps> = (props) => {
  const { keyIntegration } = props;
  const actionRefInvoicesNotes = useRef<ActionType>();
  const actionRefRoyaltiesNotes = useRef<ActionType>();
  const actionRefDevolutionInvoicesNotes = useRef<ActionType>();
  const invoicesNotesApi = useAPI(InvoicesNotesDataApi);
  const devolutionsInvoicesNotesApi = useAPI(DevolutionsInvoicesNotesDataApi);
  const royaltiesNotesApi = useAPI(RoyaltiesNotesDataApi);

  useEffect(() => {
    actionRefInvoicesNotes?.current?.reload();
    actionRefRoyaltiesNotes?.current?.reload();
    actionRefDevolutionInvoicesNotes?.current?.reload();
  }, [keyIntegration]);

  const clientBillingColumns: ProColumns[] = [
    {
      title: "id",
      dataIndex: "id",
      hidden: true,
      hideInSearch: true,
    },
    {
      title: <EllipsisOutlined />,
      fixed: "right",
      key: "options",
      width: 32,
      render: (_, record) => (
        <ClientBillingEdit
          filters={{ id: record.id }}
          iconMode
          onConfirm={() => actionRefInvoicesNotes?.current?.reload()}
        />
      ),
    },
    {
      title: EditDataFields["status"],
      dataIndex: "status",
      width: 100,
      renderText: (value: string) => (
        <>
          <TagInvoice value={value} />
        </>
      ),
    },
    {
      title: EditDataFields["editDataMultiplierIdentifierNumber"],
      dataIndex: "editDataMultiplierIdentifierNumber",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataNfe"],
      dataIndex: "editDataNfe",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataBatch"],
      dataIndex: "editDataBatch",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataIdentifierNumber"],
      dataIndex: "editDataIdentifierNumber",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataUf"],
      dataIndex: "editDataUf",
      ellipsis: true,
      width: 30,
    },
    {
      title: EditDataFields["editDataCity"],
      dataIndex: "editDataCity",
      ellipsis: true,
      width: 90,
    },
    {
      title: EditDataFields["editDataBillingDate"],
      dataIndex: "editDataBillingDate",
      ellipsis: true,
      width: 90,
    },
    {
      title: EditDataFields["editDataSeedUse"],
      dataIndex: "editDataSeedUse",
      ellipsis: true,
      width: 60,
    },
    {
      title: EditDataFields["editDataCultivate"],
      dataIndex: "editDataCultivate",
      ellipsis: true,
      width: 40,
    },
    {
      title: EditDataFields["editDataQtyPackaging"],
      dataIndex: "editDataQtyPackaging",
      ellipsis: true,
      width: 40,
    },
    {
      title: EditDataFields["editDataClientName"],
      dataIndex: "editDataClientName",
      ellipsis: true,
      width: 200,
    },
    {
      title: EditDataFields["editDataTechnology"],
      dataIndex: "editDataTechnology",
      ellipsis: true,
      width: 60,
    },
    {
      title: EditDataFields["editDataWeightPackaging"],
      dataIndex: "editDataWeightPackaging",
      ellipsis: true,
      width: 60,
    },
    {
      title: EditDataFields["editDataWeightKq"],
      dataIndex: "editDataWeightKq",
      ellipsis: true,
      width: 40,
    },
  ];

  const clientDevolutionNotes: ProColumns[] = [
    {
      title: "id",
      dataIndex: "id",
      hidden: true,
      hideInSearch: true,
    },
    {
      title: EditDataFields["editDataNfe"],
      dataIndex: "editDataNfe",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataDevolutionNfe"],
      dataIndex: "editDataDevolutionNfe",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataIdentifierNumber"],
      dataIndex: "editDataIdentifierNumber",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataBatch"],
      dataIndex: "editDataBatch",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataCustomerName"],
      dataIndex: "editDataCustomerName",
      ellipsis: true,
      width: 30,
    },
    {
      title: EditDataFields["editDataMultiplierIdentifierNumber"],
      dataIndex: "editDataMultiplierIdentifierNumber",
      ellipsis: true,
      width: 90,
    },
    {
      title: EditDataFields["editDataWeightKq"],
      dataIndex: "editDataWeightKq",
      ellipsis: true,
      width: 90,
    },
  ];

  const tabsItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Notas faturamento",
      children: (
        <ProTable
          scroll={{
            x: true,
          }}
          actionRef={actionRefInvoicesNotes}
          columns={clientBillingColumns}
          request={(params) => {
            const query = {
              page: params?.current,
              pageSize: params?.pageSize,
              ...params,
            } as InvoicesNotesDataControllerFindAllRequest;
            query.keyIntegration = keyIntegration;
            return invoicesNotesApi
              .invoicesNotesDataControllerFindAll(query)
              .then((data) => {
                return {
                  data: data.list,
                  success: true,
                  total: data.total,
                };
              });
          }}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
          }}
          rowKey="id"
          search={false}
          options={false}
        />
      ),
    },
    {
      key: "2",
      label: "Notas ITS",
      children: (
        <ProTable
          scroll={{
            x: true,
          }}
          actionRef={actionRefRoyaltiesNotes}
          columns={royaltiesNotesDataColumns}
          request={(params) => {
            const query = {
              page: params?.current,
              pageSize: params?.pageSize,
              ...params,
            } as RoyaltiesNotesDataControllerFindAllRequest;
            query.keyIntegration = keyIntegration;
            return royaltiesNotesApi
              .royaltiesNotesDataControllerFindAll(query)
              .then((data) => {
                return {
                  data: data.list,
                  success: true,
                  total: data.total,
                };
              });
          }}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
          }}
          rowKey="id"
          search={false}
        />
      ),
    },
    {
      key: "3",
      label: "Notas devolução",
      children: (
        <ProTable
          scroll={{
            x: true,
          }}
          actionRef={actionRefDevolutionInvoicesNotes}
          columns={clientDevolutionNotes}
          request={(params) => {
            const query = {
              page: params?.current,
              pageSize: params?.pageSize,
              ...params,
            } as InvoicesNotesDataControllerFindAllRequest;
            query.keyIntegration = keyIntegration;
            return devolutionsInvoicesNotesApi
              .devolutionsInvoicesNotesDataControllerFindAll(query)
              .then((data) => {
                return {
                  data: data.list,
                  success: true,
                  total: data.total,
                };
              });
          }}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
          }}
          rowKey="id"
          search={false}
          options={false}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={tabsItems} style={{ padding: 6 }} />;
};

export default InvoicesRecords;
