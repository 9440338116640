import React, { useState, createRef, useEffect } from "react";
import { Button, Drawer, Empty, Select, Space, Spin, Typography } from "antd";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-components";
import { ProCard, ProTable, ActionType } from "@ant-design/pro-components";
import { useAPI } from "../../../client-api/utils/api";
import { ReportsApi } from "../../../client-api";
import {
  defaultFilter,
  textToIdentificationNumber,
} from "../../../components/utils";
import { InvoiceDef } from "../../../components/invoices-status/types";
import TagInvoice from "../../../components/invoices-status/tag-invoice";
import InvoicesRecords from "../../../components/invoices-records/indes";
import ClientBillingEdit from "../../../components/client-billing-edit/indes";

import "./style.css";

const InvoiceRecords: React.FC = () => {
  const api = useAPI(ReportsApi);

  const [keyIntegration, setKeyIntegration] = useState<string>();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [page, setpage] = useState<number>(1);
  const [filters, setFilters] = useState<any>({});
  const [total, setTotal] = useState<number>(0);

  const ContainerHeight = 400;

  const actionRef: any = createRef<ActionType>();

  const status = [
    {
      value: "not_reported",
      text: InvoiceDef["not_reported"].label,
      label: InvoiceDef["not_reported"].label,
    },
    {
      value: "miss_invoices",
      text: InvoiceDef["miss_invoices"].label,
      label: InvoiceDef["miss_invoices"].label,
    },
    {
      value: "inconsistent_report",
      text: InvoiceDef["inconsistent_report"].label,
      label: InvoiceDef["inconsistent_report"].label,
    },
    {
      value: "report_completed",
      text: InvoiceDef["report_completed"].label,
      label: InvoiceDef["report_completed"].label,
    },
  ];

  const columns: ProColumns[] = [
    {
      title: "Status",
      key: "reportResult",
      dataIndex: "reportResult",
      width: 150,
      onFilter: (value, record) => true,
      filters: status,
      filterMultiple: false,
      ellipsis: true,
      renderText: (value: string) => (
        <>
          <TagInvoice value={value} />
        </>
      ),
      renderFormItem: (__, props) => <Select {...props} options={status} />,
    },
    {
      title: "NF",
      key: "nfe",
      dataIndex: "nfe",
      width: 100,
      ...defaultFilter("nfe"),
    },
    {
      title: "cpf/cnpj",
      key: "cpu",
      width: 150,
      ellipsis: false,
      dataIndex: "identifierNumber",
      renderText: (text) => (
        <Typography.Text title={text} copyable={{ text }}>
          {textToIdentificationNumber(text)}
        </Typography.Text>
      ),
      ...defaultFilter("identifierNumber"),
    },
    {
      title: "Lote",
      key: "batch",
      width: 150,
      dataIndex: "batch",
      renderText: (text) => (
        <Typography.Text title={text} copyable={{ text }}>
          {text}
        </Typography.Text>
      ),
      ellipsis: true,
      ...defaultFilter("batch"),
    },
    {
      title: "Tecnologia",
      key: "technology",
      width: 150,
      dataIndex: "technology",
      renderText: (text) => (
        <Typography.Text title={text} copyable={{ text }}>
          {text}
        </Typography.Text>
      ),
      ...defaultFilter("technology"),
    },
    {
      title: "Uso da semente",
      key: "seedUse",
      width: 150,
      dataIndex: "seedUse",
      ...defaultFilter("seedUse"),
    },
    {
      title: "Qtd Faturamento",
      key: "invoicesTotalQty",
      width: 75,
      ellipsis: true,
      dataIndex: "invoicesTotalQty",
      renderText(_, record) {
        return (
          <Typography.Text>
            {record.invoicesTotalQty - record.devolutionsInvoicesTotalQty}
            {record.devolutionsInvoicesTotalQty && (
              <Typography.Text type="danger">
                {" "}
                ({record.devolutionsInvoicesTotalQty})
              </Typography.Text>
            )}
          </Typography.Text>
        );
      },
    },
    {
      title: "Qtd devolucao",
      key: "devolutionsInvoicesTotalQty",
      hideInTable: true,
      hideInSearch: true,
      dataIndex: "devolutionsInvoicesTotalQty",
    },
    {
      title: "Qtd ITS",
      key: "royaltiesTotalQty",
      width: 75,
      ellipsis: true,
      dataIndex: "royaltiesTotalQty",
    },
    {
      hideInTable: true,
      key: "keyIntegration",
      ellipsis: true,
      dataIndex: "keyIntegration",
    },
    {
      title: <EllipsisOutlined />,
      fixed: "right",
      key: "options",
      width: 32,
      render: (_, record) => (
        <Space.Compact block>
          <Button
            icon={<SearchOutlined />}
            size="small"
            onClick={() => {
              if (record.keyIntegration) {
                setOpen(true);
                setKeyIntegration(record.keyIntegration);
              }
            }}
          />
          <ClientBillingEdit
            filters={{ keyIntegration: record.keyIntegration }}
            iconMode
            onConfirm={() => actionRef?.current?.reload()}
          />
        </Space.Compact>
      ),
    },
  ];

  useEffect(() => {
    setData([]);
    appendData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    appendData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters]);

  const appendData = () => {
    const pageSize = 30;
    setLoading(true);
    api
      .reportsControllerGetNotes({
        page: page,
        pageSize: pageSize,
        ...filters,
      })
      .then((response) => {
        if (response?.total > 0 && response?.list?.length > 0) {
          setTotal(response.total);
          setData(data.concat(response.list));
        }
      })
      .finally(() => setLoading(false));
  };

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.scrollTop -
          ContainerHeight
      ) <= 1
    ) {
      setLoading(true);
      setpage(page + 1);
    }
  };

  return (
    <ProCard split="vertical" style={{ marginTop: 15 }}>
      <ProTable
        actionRef={actionRef}
        loading={loading}
        footer={() => (
          <>
            {`exibindo ${data?.length} de ${total}`}
            {loading ? (
              <>
                {" "}
                [carregando <Spin percent={"auto"} size="small" />]
              </>
            ) : undefined}
          </>
        )}
        columns={columns}
        dataSource={data}
        request={(params, _, filter) => {
          const filters = { ...filter, ...params };
          delete filters.current;
          delete filters.pageSize;
          setData([]);
          setFilters(filters);
          if (page !== 1) setpage(1);
          setKeyIntegration(undefined);
          return Promise.resolve([]);
        }}
        rowKey="keyIntegration"
        toolbar={{
          title: <b>Painel de consolidação de notas</b>,
        }}
        options={false}
        pagination={false}
        onScroll={onScroll}
        scroll={{ x: 800, y: ContainerHeight }}
        search={false}
      />
      <Drawer
        open={open}
        title={"Registros"}
        onClose={() => setOpen(false)}
        width={window.innerWidth * 0.9}
      >
        {keyIntegration ? (
          <InvoicesRecords keyIntegration={keyIntegration} />
        ) : (
          <Empty />
        )}
      </Drawer>
    </ProCard>
  );
};

export default InvoiceRecords;
